<template>
    <button :style="{ background: color }" v-if="!link" >
        <slot></slot>
    </button>
    <router-link :to="to" :style="{ background: color }" v-else>
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        props: {
          link: {
              type: Boolean,
              required: false,
              default: false
          },
          to: {
              type: String,
              required: false,
              default: '/'
          },
          color: {
              type: String,
              required: false,
              default: '#ed633a',
          }
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped>
    button, a {
        cursor: pointer;
    }
</style>
